import React from "react";

import styled from "styled-components";
import { ScheduleContainer } from "../../../../components/schedule/container/ScheduleContainer";
import { COLORS, INTL } from "../../../../common/constants";
import { SectionTemplate } from "../../../../common/ui/organisms/SectionTemplate";

type TSchedulePageProps = {
  className?: string;
};
export const Schedule = ({ className }: TSchedulePageProps) => {
  return (
    <SectionTemplateStyled
      className={className}
      id="schedule"
      title={INTL.SCHEDULE_PAGE.TITLE}
    >
      <ScheduleContainer />
    </SectionTemplateStyled>
  );
};

const SectionTemplateStyled = styled(SectionTemplate)`
  position: relative;
  border-radius: 72px 72px 0 0;
`;
