import React from "react";
import styled from "styled-components";
import { SectionTemplate } from "../../../../common/ui/organisms/SectionTemplate";

export const Advertisement = () => (
  <SectionTemplate id="banner" title={"Специальные предложения"}>
    <ContainerStyle>
      <ImgStyled src={require("../../../../assets/Banner.svg")}></ImgStyled>
    </ContainerStyle>
  </SectionTemplate>
);

const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgStyled = styled.img`
  width: 100%;
  height: 100%;
  max-height: 80vh;
`;
