import React from "react";
import { COLORS, INTL } from "../../../../common/constants";
import { DirectionsListContainer } from "../../../../components/directions/container/DirectionsListContainer";
import { SectionTemplate } from "../../../../common/ui/organisms/SectionTemplate";
import styled from "styled-components";

type TMainProps = {
  className?: string;
};
export const Directions = ({ className }: TMainProps) => {
  return (
    <SectionTemplateStyled className={className} id='directions' title={INTL.DIRECTIONS_PAGE.TITLE}>
      <LayoutStyle>
        <DirectionsListContainer />
        </LayoutStyle>
    </SectionTemplateStyled>
  );
};

const SectionTemplateStyled = styled(SectionTemplate)`
  padding: 72px 16px 0 16px;
`;
const LayoutStyle = styled.div`
display:flex;
justify-content: center;
width: 100%;
`;