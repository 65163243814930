import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { LogoIcon } from "../../../assets/icons";
import { COLORS } from "../../../common/constants";

export const FooterContainer = ({ isColor }: { isColor?: boolean }) => {
  return (
    <Container maxWidth="xl">
      <BoxStyled>
        <LogoIconStyled />
        <Box>
          <Typography>Самозанятая Осокина Асия Аветисовна</Typography>
          <Typography>ИНН 773608909737, г. Москва</Typography>
        </Box>
      </BoxStyled>
    </Container>
  );
};

const LogoIconStyled = styled(LogoIcon)`
  height: 36px;
  width: 36px;
`;

const BoxStyled = styled(Box)`
  display: flex;
  gap: 38px;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.WHITE};
  padding: 14px 0;
`;
