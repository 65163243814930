import React from "react";
import styled from "styled-components";

import { COLORS, INTL } from "../../../common/constants";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { advantagesList } from "../constants/rent";

type TRentContainerProps = {
  className?: string;
};

export const RentContainer = ({ className }: TRentContainerProps) => (
  <ContainerStyle className={className}>
    <GridStyled container rowSpacing={1} columnSpacing={1}>
      {advantagesList.map((title, key) => (
        <Grid key={key} item>
          <Bubble>{title}</Bubble>
        </Grid>
      ))}
    </GridStyled>
    <MainLayoutStyle>
      <Typography
        color={COLORS.WHITE}
        sx={{ typography: { xs: "h4", sm: "h2" } }}
      >
        {INTL.RENT_PAGE.TITLE}
      </Typography>
      <Button
        component="a"
        href="https://widget.musbooking.com/?room=1a2e032d-ec93-4b53-a86c-e3bafad064a7&rg=1&themeActive=181817"
        target="_blank"
        fullWidth={false}
        sx={{
          mt: 3,
          "&.MuiButtonBase-root:hover": {
            bgcolor: COLORS.WHITE,
            borderColor: COLORS.WHITE,
            color: COLORS.BLACK_1,
          },
        }}
        variant="outlined"
        size="large"
        color="inherit"
      >
        Забронировать
      </Button>
    </MainLayoutStyle>
  </ContainerStyle>
);

const ContainerStyle = styled.div`
position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.WHITE};
  width: 100%;
  height: 100%;
`;

const GridStyled = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
`;

const MainLayoutStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bubble = styled.div`
  display: flex;
  padding: 4px 21px;
  border: 1px solid ${COLORS.WHITE};
  color: ${COLORS.WHITE};
  border-radius: 30px;
`;
