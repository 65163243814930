export const  INTL = {
    SIARTY_DANCE_STUDIO: 'SIARTY DANCE STUDIO',
    ABOUT_US: 'О нас',
    HEADER: {
        MENU: {
            STYLES: 'НАПРАВЛЕНИЯ',
            SCHEDULE: 'РАСПИСАНИЕ',
            PRICE: 'ЦЕНЫ',
            RENT: 'АРЕНДА ЗАЛА',
            CONTACT: 'КОНТАКТЫ'
            
        }
    },
    MAIN_PAGE: {
        SIGN_UP: 'ЗАПИСАТЬСЯ'
    },
    DIRECTIONS_PAGE: {
        TITLE: 'ВЫБЕРИ СВОЙ СТИЛЬ',
        STRIP_PLASTIC_SCRATCH: 'STRIP (С НУЛЯ)',
        STRIP_PLASTIC_BEGINNERS: 'STRIP (НАЧИН)',
        STRIP_PLASTIC_PRO: 'FRAME UP STRIP',
        DANCEHALL: {
            TITLE: 'DANCEHALL',
            DESCRIPTION: 'Маша'
        },
        CHOREO: 'CHOREO',
        TWERK: 'TWERK',
        LADY_STYLE: 'LADIES STYLE',
        LADY_STYLE_SCRATCH: 'LADIES STYLE (С НУЛЯ)',
        STRETCHING: 'РАСТЯЖКА + ФИТНЕС'
    },
    SCHEDULE_PAGE: {
        TITLE: 'РАСПИСАНИЕ',
        WEEKDAY: {
            MONDAY: 'MONDAY',
            TUESDAY: 'TUESDAY',
            WEDNESDAY: 'WEDNESDAY',
            THURSDAY: 'THURSDAY',
            FRIDAY: 'FRIDAY'
        },
        WEEKDAY_RU: {
            MONDAY: 'ПН',
            TUESDAY: 'ВТ',
            WEDNESDAY: 'СР',
            THURSDAY: 'ЧТ',
            FRIDAY: 'ПТ'
        }
    },
    PRICE_PAGE: {
        TITLE: 'СТОИМОСТЬ',
        FIRST_LESSON: {
            TITLE: 'Пробное занятие',
            PRICE: '750₽'
        },
        ONE_LESSON: {
            TITLE: 'Разовое занятие',
            PRICE: '1000₽'
        },
        TICKETS: {
            TITLE: 'АБОНЕМЕНТ',
            FOUR_CLASSES: {
                TITLE: 'Абонемент на 4 занятия',
                TIME: 'Действителен 30 дней',
                FULL_PRICE: '3.700₽',
                ONE_PRICE: '925₽'
            },
            EIGHT_CLASSES: {
                TITLE: 'Абонемент на 8 занятий',
                TIME: 'Действителен 30 дней',
                FULL_PRICE: '6.800₽',
                ONE_PRICE: '850₽'
            },
            SIXTEEN_CLASSES: {
                TITLE: 'Абонемент на 16 занятий',
                TIME: 'Действителен 2 месяца',
                FULL_PRICE: '12.000₽',
                ONE_PRICE: '750₽'
            },
            UNLIMITED: {
                TITLE: 'Безлимитный на 2 месяца',
                TIME: 'Действителен 2 месяца',
                FULL_PRICE: '15.000₽'
            }
        }
    },
    CONTACTS_PAGE: {
        TITLE: 'КОНТАКТЫ'
    },
    RENT_PAGE: {
        TITLE: 'АРЕНДА ЗАЛА'
    }
};
